import { HomePage_Hero } from "./hero/HomePage_Hero";
import { HomePage_Section } from "./section/HomePage_Section";

import { usePopupAlert } from "../utils/PopupAlert";
import { useHtmlClassName } from "../utils/useHtmlClassName";

import "./homePage.css";

import "../firebase/FirebaseInit";

export const HomePage = () => {
  const [PopupAlert, showPopupAlert] = usePopupAlert();

  useHtmlClassName("homepage");

  return (
    <div id="homepage">
      <HomePage_Hero />
      <HomePage_Section />

      <PopupAlert />
    </div>
  );
};
