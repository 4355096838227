import { useEffect } from "react";

export const useHtmlClassName = (name: string) => {
  return useEffect(() => {
    const html = document.querySelector("html") as HTMLElement;
    if (!html.classList.contains(name)) {
      html.classList.add(name);
    }

    return () => {
      if (html.classList.contains(name)) {
        html.classList.remove(name);
      }
    };
  }, []);
};