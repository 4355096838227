import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import PlanetHeart from "../../assets/planet-heart.png";

import "./homePage_hero.css";
import StarField from "../../starfield/StarField";

export const HomePage_Hero = () => {
  return (
    <header className="hero">
      <StarField
        style={{
          width: "100%",
          height: "100%",
          // background: "black",
          backgroundImage: `url(${require("../../assets/sky.jpeg")})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "absolute",
        }}
      />

      <div
        style={{
          position: "absolute",
          zIndex: 0,
          width: "100%",
          height: "100%",
          display: "grid",
          placeItems: "center",

          opacity: 0.4,

          overflow: "hidden",
        }}
      >
        <img
          src={PlanetHeart}
          style={{
            height: "120%",
            maxWidth: "120%",
            width: "auto",
            aspectRatio: 1,
            objectFit: "contain",
          }}
        />
      </div>

      <Container fluid>
        <Row>
          <Col xl={6} xs={12}>
            <div className="hero-text">
              <h1>
                <span className="fw-light">Planet Love</span>
                <br />
                Global Petition
              </h1>
              <h3 className="fw-light">
                Planet Love is <strong>not</strong> just a name.
              </h3>
              <a href="#petition-form" className="btn btn-lg">
                Sign the Petition
              </a>
              <br />
              <a
                href="#spread-the-message"
                style={{
                  color: "inherit",
                }}
              >
                <ul className="social-links">
                  <li className="label">Spread the Message</li>
                </ul>
              </a>
              {/* <img
                src={require("../../assets/planet-heart.webp")}
                style={{
                  height: "100%",
                  width: "auto",
                  maxWidth: "100%",
                  aspectRatio: 1,
                  objectFit: "contain",
                }}
              /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
