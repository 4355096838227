import { FormEvent, useEffect, useMemo, useState } from "react";

import {
  Button,
  Card,
  Carousel,
  CarouselItem,
  Container,
  Form,
  Table,
} from "react-bootstrap";

import CountryList from "react-select-country-list";

import { usePopupAlert } from "../../utils/PopupAlert";
import "./homePage_section.css";

import { getFormValue } from "../../utils/Essentials";
import inquirySentAnimation from "../../assets/sent-animation.json";
import PlanerLoveBanner from "../../assets/planet-love-banner.png";

import {
  subscribePetitionAsync,
  type PetitionSubscriber,
} from "../../firebase/FirebaseFunctions";
import { useLottie } from "lottie-react";
import { ButtonWithLoading } from "../../utils/ButtonWithLoading";

import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../firebase/FirebaseInit";

export const HomePage_Section = () => {
  const [PopupAlert, showPopupAlert] = usePopupAlert();
  const [signers, setSigners] = useState<
    Omit<PetitionSubscriber, "email">[] | null
  >(null);

  const countryList = useMemo(() => CountryList().getLabels(), []);
  const {
    View: LottieSentView,
    play: lottieSentPlay,
    goToAndStop: lottieSentGoToAndStop,
  } = useLottie({
    animationData: inquirySentAnimation,
    loop: false,
    autoPlay: false,
    renderer: "svg",
    rendererSettings: {
      className: "sent-animation",
    },
  });

  const [warning, setWarning] = useState("");
  const [repaint, SetRepaint] = useState(0);
  const [subscribedSuccessfully, setSubscribedSuccessfully] = useState<
    boolean | null
  >(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setWarning("");

    setSubscribedSuccessfully(null);

    const fullName = getFormValue("full-name", e);
    const email = getFormValue("email", e);
    const country = getFormValue("country", e);
    const region = getFormValue("region", e);

    if (fullName.trim().length === 0) {
      setWarning("please enter your name");
      setSubscribedSuccessfully(false);
      return;
    }

    if (email.trim().length === 0) {
      setWarning("please enter your email");
      setSubscribedSuccessfully(false);
      return;
    }

    if (country.trim().length === 0) {
      setWarning("please enter your country");
      setSubscribedSuccessfully(false);
      return;
    }

    if (region.trim().length === 0) {
      setWarning("please enter your state/region");
      setSubscribedSuccessfully(false);
      return;
    }

    try {
      const subscriber = {
        country,
        email,
        fullName,
        region,
        when_created: new Date().getTime() / 1000,
      } satisfies PetitionSubscriber;

      const signedSuccessfully = await subscribePetitionAsync(subscriber);

      if (signedSuccessfully) setSubscribedSuccessfully(true);
      else {
        setWarning("You have already signed the petition!");
        setSubscribedSuccessfully(false);
      }
    } catch (e) {
      console.log(JSON.stringify(e, null, 2));
      setWarning(
        "Something went wrong! Please check your internet connection and try again!"
      );
      setSubscribedSuccessfully(false);
    }
  };

  useEffect(() => {
    if (subscribedSuccessfully) {
      lottieSentPlay();
    } else {
      lottieSentGoToAndStop(0, true);
    }

    SetRepaint((prev) => prev + 1);
  }, [subscribedSuccessfully]);

  useEffect(() => {
    (async () => {
      const docs = (await getDocs(collection(firestore, "subscribers"))).docs;

      const subs = docs.map((doc) => {
        let data = doc.data() as Omit<PetitionSubscriber, "email"> & {
          email?: string;
        };
        delete data.email;
        return data satisfies Omit<PetitionSubscriber, "email">;
      });

      const yaron_index = subs.findIndex(
        (signer) => signer.fullName === "Yaron Farzan"
      );
      const yaron_obj = subs[yaron_index];
      subs.splice(yaron_index, 1);
      subs.sort((a, b) => {
        // if (!a.when_created && !b.when_created) {
        //   const f1 = a.fullName.toLowerCase();
        //   const f2 = b.fullName.toLowerCase();

        //   if (f1 > f2) return 1;
        //   if (f1 < f2) return -1;
        //   return 0;
        // }

        return (b.when_created ?? 0) - (a.when_created ?? 0);
      });
      subs.push(yaron_obj);

      setSigners(subs);
    })();
  }, []);

  return (
    <>
      <section>
        <Container>
          <Card className="shadow-lg">
            <Card.Body>
              <div>
                <h1>
                  <span
                    style={{
                      color: "#198754",
                    }}
                  >
                    Welcome to the
                  </span>
                  {/* &nbsp; */}
                  <br />
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#050577",
                    }}
                  >
                    Planet Love Global Petition
                  </span>
                  <div
                    style={{
                      width: "2em",
                      height: "0.1em",
                      marginTop: "0.25em",
                      backgroundColor: "#050577",
                    }}
                  />
                </h1>
                <p>
                  In the vast expanse of the cosmos, there is a remarkable
                  celestial body we call home, a place where life flourishes,
                  diversity abounds, and the wonders of nature never cease to
                  amaze us.
                  <br />
                  It is a planet that has cradled humanity, nurtured countless
                  species, and witnessed the evolution of life itself.
                  <br />
                  It is, undeniably, our most cherished abode.
                </p>
                <h3>
                  <span style={{ color: "#050577" }}>Whoever you are, </span>
                  {/* <br /> */}
                  <span style={{ color: "#198754" }}>wherever you are,</span>
                  <br />
                  <span style={{ color: "#050577" }}>
                    welcome to the Planet Love Petition –
                  </span>
                  <br />
                  <span style={{ color: "#198754" }}>
                    a heartfelt call to officially rename our beloved planet
                    Earth to "
                    <span style={{ color: "#050577" }}>Planet Love.</span>"
                  </span>
                </h3>
                <section>
                  <p>
                    Our world has undergone tremendous changes throughout its
                    existence. From the primordial oceans that first gave rise
                    to life to the awe-inspiring ecosystems that now thrive on
                    its surface, this beautiful planet has been a canvas for the
                    most beautiful and complex tapestry of existence. It has
                    taught us invaluable lessons about coexistence,
                    sustainability, and the boundless potential for love and
                    compassion.
                  </p>
                  <p>
                    As we stand at a critical juncture in human history, facing
                    environmental challenges, social issues, and a rapidly
                    changing global landscape, the time is ripe for a
                    transformation that transcends borders and unites us all.
                    The name "Planet Love" encapsulates the spirit of unity,
                    compassion, and reverence for our shared home.
                  </p>
                  <p>
                    This petition is a testament to our deep-seated belief that
                    love can be a unifying force. By renaming our planet, we
                    send a powerful message to ourselves and future generations:
                    that love for our home, its inhabitants, and the incredible
                    diversity of life it supports is paramount. Planet Love
                    stands as a symbol of our commitment to nurturing,
                    protecting, and cherishing this extraordinary place we call
                    home.
                  </p>
                  <p>
                    Join us in this collective endeavor to rekindle our
                    connection with our planet and the love that binds us to it.
                    Sign the petition, share your thoughts, and spread the
                    message of Planet Love far and wide. Together, we can make a
                    profound change, not just in name but in the way we perceive
                    and care for the world that sustains us.
                  </p>
                  <p>
                    Planet Love is not just a name; it's a declaration of our
                    shared commitment to love, protect, and preserve the
                    beautiful blue sphere we call home. Let us embark on this
                    journey together, hand in hand, as stewards of Planet Love.
                  </p>
                </section>

                <Form id="petition-form" onSubmit={onSubmit}>
                  <div
                    style={
                      !subscribedSuccessfully
                        ? {
                            display: "none",
                          }
                        : {
                            textAlign: "center",
                            color: "white",
                          }
                    }
                  >
                    <h3>Thank You for Signing the Planet Love Petition</h3>
                    {LottieSentView}
                  </div>
                  <div
                    style={
                      subscribedSuccessfully
                        ? {
                            display: "none",
                          }
                        : {}
                    }
                  >
                    <h5 style={{ color: "white" }}>
                      Sign the Petition and Make the Change!
                    </h5>

                    <br />

                    <Form.Control
                      name="full-name"
                      type="text"
                      required
                      placeholder="Full Name"
                    />
                    <Form.Control
                      name="email"
                      type="email"
                      required
                      placeholder="Email Address"
                    />
                    {/* <Form.Control
                      name="country"
                      type="text"
                      required
                      placeholder="Country"
                    /> */}
                    <Form.Select name="country" required placeholder="Country">
                      {countryList.map((value, index) => (
                        <option key={index} value={value}>
                          {value}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control
                      name="region"
                      type="text"
                      required
                      placeholder="City/Village and State/Province"
                    />
                    <span
                      style={{
                        // color: "#dc3545",
                        color: "white",
                      }}
                    >
                      {warning}
                    </span>
                    <br />
                    <br />
                    <ButtonWithLoading
                      isLoading={subscribedSuccessfully === null}
                      type="submit"
                      style={{
                        backgroundColor: "rgb(154, 117, 191)",
                        borderColor: "rgb(154, 117, 191)",
                      }}
                    >
                      <span>
                        Yes, I agree to change the name of Planet Earth to
                        Planet Love
                      </span>
                    </ButtonWithLoading>
                    <br />
                    <span
                      style={{
                        color: "white",
                        fontSize: "0.7rem",
                      }}
                    >
                      By signing the Planet Love Global Petition you agree that
                      your first name and your last name will be shown in the
                      list of signers. Your email address is kept confidential.
                    </span>
                  </div>
                </Form>
                <br />
                <p>
                  Planet Love is more than a name; it's a promise to nurture and
                  protect our shared home. Join us on this incredible journey
                  towards a world united by love, respect, and a deep
                  appreciation for the planet we call home. Together, we can
                  make Planet Love a reality!
                </p>

                <br />

                <h3 style={{ color: "#198754" }}>Spread the Message</h3>
                <section id="spread-the-message">
                  <p>
                    Help us spread the message of Planet Love far and wide.
                    Share this petition with your friends, family, and social
                    networks. Let's ignite a global movement that celebrates
                    love for our planet and all its inhabitants.
                  </p>
                  <p>
                    Join us in renaming our beloved planet Earth to Planet Love,
                    and together, let's shape a brighter future for generations
                    to come.
                  </p>
                  <p>
                    The words we use to define our world directly influence how
                    we live in it. When we speak words that possess the highest
                    and best meaning, we create for ourselves a world that
                    reflects the meaning imbued in these words. And what is
                    higher than Love? Shouldn't "love" be the word we all use to
                    describe our home? When we speak the word "love" to describe
                    our world, we generate the experience of all that love
                    means. Imagine everyone speaking the word "love" every time
                    we speak of the planet on which we live.
                  </p>
                  <p>
                    For the first time in recorded history, as a commitment to
                    love, this global petition has been presented for the sake
                    of humanity and all other living beings as a confirmation of
                    the love we share... a testament that this generation is the
                    one to take bold action and bring more love to the planet we
                    call home... a sincere gesture to future generations that we
                    hold love as the highest principle by which we lead our
                    lives.
                  </p>
                  <p>
                    P.S. In the near future the petition will be expanded to
                    include planets in our solar system.
                  </p>
                </section>

                <img
                  alt="planet love banner"
                  src={PlanerLoveBanner}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "0.725rem",
                  }}
                />
              </div>

              {signers && (
                <>
                  <br />
                  <Table>
                    <thead>
                      <th>#</th>
                      <th>Signer name</th>
                      <th>Signer region</th>
                    </thead>
                    <tbody>
                      {signers.map((s1, index) => {
                        return (
                          <tr>
                            <td>{signers.length - index}</td>
                            <td>{s1.fullName}</td>
                            <td>{[s1.region, s1.country].join(", ")}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
            </Card.Body>
          </Card>
        </Container>
      </section>
      <PopupAlert />
    </>
  );
};
