import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyCbpMlJOJ8sIpyMG3FN3D_W6w7xWVxc6nM",
  authDomain: "yaron-planet-love.firebaseapp.com",
  projectId: "yaron-planet-love",
  storageBucket: "yaron-planet-love.appspot.com",
  messagingSenderId: "911751805147",
  appId: "1:911751805147:web:863f363b7d4c561306f4fe",
  measurementId: "G-VHXHC10C0B",
};

const app = initializeApp(firebaseConfig);
const firestore = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LdNGG4oAAAAAJ_T8t3qqs70cADvxh3ti8WbVCKs"),
  isTokenAutoRefreshEnabled: true,
});

export { app, firestore, appCheck };
