import { useCallback, useEffect, useState } from "react";

import { useLottie } from "lottie-react";
import { AiOutlineClose } from "react-icons/ai";

import FailureAnimation from "../assets/popup-alert-animation/action_failure_animation.json";
import SuccessAnimation from "../assets/popup-alert-animation/action_success_animation.json";

import "./popupAlert.css";

export type PopupAlertProps = {
  type: "success" | "failure";
  title?: string;
  text?: string;
};

export const usePopupAlert = () => {
  const [show, setShow] = useState(false);
  const [props, setProps] = useState<PopupAlertProps>({
    type: "success",
    text: "",
    title: "",
  });

  const showPopupAlert = (p: PopupAlertProps) => {
    setProps(p);
    setShow(true);
  };

  const AlertComponent = useCallback(() => {
    function Component() {
      const {
        View: LottieView,
        setSpeed: setLottieSpeed,
        play: playLottie,
        goToAndStop: goToAndStopLottie,
      } = useLottie({
        animationData:
          props.type === "failure" ? FailureAnimation : SuccessAnimation,
        loop: false,
        autoPlay: false,
        rendererSettings: {
          // className: "password-lottie-animation",
        },
        renderer: "svg",
      });

      useEffect(() => {
        setLottieSpeed(1);

        let timeout: NodeJS.Timeout;
        if (show) {
          playLottie();

          timeout = setTimeout(() => {
            setShow(false);
          }, 4000);
        } else {
          goToAndStopLottie(0, true);
        }

        return () => {
          goToAndStopLottie(0, true);
          if (timeout) clearTimeout(timeout);
        };
      }, [show]);

      if (!show) return null;

      return (
        <div className="popup-alert shadow-lg">
          <div className="alert-status-row">
            <div className="lottie-container">{LottieView}</div>
            <div className="title-container">{props.title}</div>
            <button
              className="close-button-container"
              role="button"
              onClick={() => {
                setShow(false);
              }}
            >
              <AiOutlineClose color="#444" />
            </button>
          </div>
          <div className="text-container">{props.text}</div>
          <div className="auto-disapear-loader"></div>
        </div>
      );
    }

    return <Component />;
  }, [props, show]);

  return [AlertComponent, showPopupAlert] as [
    () => JSX.Element,
    (p: PopupAlertProps) => void
  ];
};
