import { addDoc, collection, doc, getDoc, setDoc } from "firebase/firestore";
import { firestore as db } from "./FirebaseInit";

export type PetitionSubscriber = {
  fullName: string;
  email: string;
  country: string;
  region: string;
  when_created?: number;
};

export async function subscribePetitionAsync(subscriber: PetitionSubscriber) {
  const subDoc = doc(db, "subscribers", subscriber.email);

  if ((await getDoc(subDoc)).exists()) {
    return Promise.resolve(false);
  }
  await setDoc(subDoc, subscriber);
  return Promise.resolve(true);
}
