// src/components/Starfield.tsx
import React, { useEffect, useRef, useState } from "react";
import "./Starfield.css";

interface StarfieldProps {
  style?: React.CSSProperties;
}

interface Star {
  x: number;
  y: number;
  size: number;
  speed: number; // Add speed property to control star movement speed
}

function Starfield({ style }: StarfieldProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const [repaint, setRepaint] = useState(0);

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctxRef.current = ctx;
    const stars: Star[] = [];

    canvas.width = canvas.clientWidth;
    canvas.height = canvas.clientHeight;

    function createStar() {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const size = Math.random() * 2;
      const speed = Math.random() * 2 + 1; // Adjust speed for variation
      stars.push({ x, y, size, speed });
    }

    function moveStars() {
      stars.forEach((star, index) => {
        star.x -= star.speed; // Change direction to simulate stars coming closer
        if (star.x < 0) {
          stars.splice(index, 1);
          createStar();
        }
      });
    }

    function drawStars() {
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "white";

      stars.forEach((star) => {
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.size, 0, Math.PI * 2);
        ctx.fill();
      });
    }

    function animate() {
      moveStars();
      drawStars();
      return requestAnimationFrame(animate);
    }

    for (let i = 0; i < 400; i++) {
      createStar();
    }

    let handle = animate();
    setRepaint((prev) => prev + 1);

    // Cleanup function if needed
    return () => {
      if (handle) cancelAnimationFrame(handle);
    };
  }, [canvasRef.current, dimensions]);

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="starfield-container" style={style}>
      <canvas
        id="stars-canvas"
        ref={canvasRef}
        className="starfield-canvas"
        width={document.body.clientWidth}
        height={document.body.clientHeight}
      ></canvas>
    </div>
  );
}

export default Starfield;
