import { ReactNode } from "react";

import { Button, ButtonProps } from "react-bootstrap";
import ReactLoading from "react-loading";

interface CustomButtonProps extends ButtonProps {
  isLoading: boolean;
  setLoading?: (flag: boolean) => void;
  onClick?: () => Promise<void>;
  children: ReactNode;
  dontShowIcon?: boolean;
  dontShowLoadingText?: boolean;
}

export const ButtonWithLoading: React.FC<CustomButtonProps> = ({
  isLoading,
  setLoading,
  onClick,
  children,
  dontShowIcon,
  dontShowLoadingText,
  ...nativeButtonProps
}: CustomButtonProps) => {
  return (
    <Button
      {...nativeButtonProps}
      disabled={isLoading || nativeButtonProps.disabled}
      onClick={async () => {
        if (setLoading) {
          setLoading(true);
        }

        if (onClick) {
          await onClick();
        }

        if (setLoading) {
          setLoading(false);
        }
      }}
    >
      {isLoading ? (
        <>
          {!dontShowIcon && (
            <ReactLoading
              className="d-inline-block"
              type="spin"
              color="white"
              width="1rem"
              height="1rem"
            />
          )}
          {!dontShowLoadingText && (
            <span style={{ marginRight: "0.375rem" }}>
              signing the petition
            </span>
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </Button>
  );
};
